<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title" v-if="userOfficeTypeId === 23">{{ $t('tcbconfiguration.dc_offcie_panel') }}</h4>
        <h4 class="card-title" v-else>{{ $t('demo.welcome_license_service_dashboard') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row v-if="orgAdmin">
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group
                label-for="notice_id"
              >
                <template v-slot:label>
                  {{ $t('exportTrophyCircular.memo_no') }}
                </template>
                  <b-form-select
                    plain
                    v-model="search.notice_id"
                    :options="circularList"
                    @change="searchData"
                    id="organization"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
        </b-row>
      </template>

      <div class="p-3" v-if="orgAdmin">
        <b-overlay :show="loader">
          <div class="row" v-if="userOfficeTypeId !== 23">
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <router-link :to="{ name: 'license_registration_service.application_list' }">
                  <b-card bg-variant="primary" class="text-center">
                      <h4 class="text-light">{{$n(status.app_list)}}</h4>
                    <b-card-text b-card-text class="text-light">{{$t('stock_management.application_list')}}</b-card-text>
                  </b-card>
                </router-link>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <router-link :to="{ name: 'license_registration_service.document_verification' }">
                  <b-card bg-variant="primary" class="text-center">
                      <h4 class="text-light">{{$n(status.verification)}}</h4>
                    <b-card-text b-card-text class="text-light">{{$t('stock_management.application_verification')}}</b-card-text>
                  </b-card>
                </router-link>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <router-link :to="{ name: 'license_registration_service.inspection' }">
                  <b-card bg-variant="primary" class="text-center">
                      <h4 class="text-light">{{$n(status.inspection)}}</h4>
                    <b-card-text b-card-text class="text-light">{{$t('stock_management.inspection')}}</b-card-text>
                  </b-card>
                </router-link>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <router-link :to="{ name: 'license_registration_service.approval' }">
                  <b-card bg-variant="primary" class="text-center">
                      <h4 class="text-light">{{$n(status.approval)}}</h4>
                    <b-card-text b-card-text class="text-light">{{$t('stock_management.approval')}}</b-card-text>
                  </b-card>
                </router-link>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <router-link :to="{ name: 'license_registration_service.agreement' }">
                  <b-card bg-variant="primary" class="text-center">
                      <h4 class="text-light">{{$n(status.agreement)}}</h4>
                    <b-card-text b-card-text class="text-light">{{$t('stock_management.agreement')}}</b-card-text>
                  </b-card>
                    </router-link>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-card bg-variant="info" class="text-center">
                      <h4 class="text-light">{{$n(status.totalApp)}}</h4>
                    <b-card-text b-card-text class="text-light">{{$t('demo.total')}} {{$t('demo.application')}}</b-card-text>
                  </b-card>
              </b-col>
          </div>
          <div class="row" v-else>
            <!-- <pre>{{ status }}</pre> -->
              <b-col xs="12" sm="12" md="8" lg="8" xl="8" offset="2">
                <router-link :to="{ name: 'license_registration_service.inspection' }">
                  <b-card style="height: 150px;font-size: 24px;" bg-variant="primary" class="text-center">
                      <h4 style="margin-top: 45px" class="text-light">{{$n(dcInspection)}}</h4>
                    <b-card-text b-card-text class="text-light">{{$t('stock_management.inspection')}}</b-card-text>
                  </b-card>
                </router-link>
              </b-col>
          </div>
        </b-overlay>
      </div>
      <!-- search section end -->

       <!-- for chairman dashboard -->
       <div v-if="orgAdmin">
          <!-- tcb dealer dashboard -->
          <TcbDealer/>
          <!-- tcb dealer allocation dashboard -->
          <TcbAllocation/>
          <!-- tcb stock dashboard -->
          <TcbRegionStock/>
       </div>

    </card>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { dasbhoard, dasbhoardDcOffice, circularsApi } from '../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import { helpers } from '@/utils/helper-functions'
import TcbDealer from '../../../ministry/dashboard/pages/components/TcbDealer.vue'
import TcbAllocation from '../../../ministry/dashboard/pages/components/TcbAllocation.vue'
import TcbRegionStock from './TcbRegionStock'

export default {
   mixins: [ModalBaseMasterList],
   components: { TcbDealer, TcbAllocation, TcbRegionStock },
  data () {
    return {
      search: {
        notice_id: 0
      },
      orgAdmin: false,
      userOfficeTypeId: 0,
      dcInspection: 0,
      baseUrl: licenseRegistrationServiceBaseUrl,
      application_list: 'license-management/application-list',
      loader: false,
      itemList: [],
      circularList: [],
      status: {},
      show: false
    }
  },
  created () {
    this.getCirculars()
    this.userOfficeTypeId = parseInt(this.$store.state.Auth.authUser.office_detail.office_type_id)
    this.orgAdmin = this.$store.state.Auth.authUser.org_admin === 2 ?? true
  },
  computed: {
     currentLocale () {
          return this.$i18n.locale
      },
    regionalOfficeList (orgId = null) {
      return this.$store.state.CommonService.commonObj.officeList.filter(item => (item.status === 1 && item.org_id === 7 && item.is_regional_office === 1))
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.circularList.map(item => {
                  const customItem = { text: this.$i18n.locale === 'bn' ? helpers.convertEnglishToBanglaNumber(item.text_bn) : item.text_en }
                return Object.assign(item, customItem)
            })
        }
    }
  },
  methods: {
    async getCirculars () {
      this.loadData = true
      this.loader = true
      let result = null
      result = await RestApi.getData(licenseRegistrationServiceBaseUrl, circularsApi)
      if (result.success) {
          this.circularList = result.data.map(item => {
                                const customItem = { text: this.$i18n.locale === 'bn' ? helpers.convertEnglishToBanglaNumber(item.text_bn) : item.text_en }
                                return Object.assign(item, customItem)
                            })
          if (result.data.length > 0) {
            this.search.notice_id = result.data[0].value
            this.searchData()
          }
          this.loadData = false
          this.show = true
          this.loader = false
      } else {
          this.loadData = false
          this.loader = false
      }
    },
    async searchData () {
      this.loadData = true
      this.loader = true
      let result = null
      const params = Object.assign({}, this.search)
      // userOffieTypeId 23 menas dc Office
      if (this.userOfficeTypeId === 23) {
        result = await RestApi.getData(licenseRegistrationServiceBaseUrl, dasbhoardDcOffice, params)
      } else {
        result = await RestApi.getData(licenseRegistrationServiceBaseUrl, dasbhoard, params)
      }
      if (result.success) {
          if (this.userOfficeTypeId === 23) {
            this.dcInspection = result.status
          } else {
            this.status = result.status
            this.itemList = result.data.map((item, index) => {
              return Object.assign({}, item, { serial: index })
            })
          }
          this.loadData = false
          this.show = true
          this.loader = false
      } else {
          this.loadData = false
          this.loader = false
      }
    }
  }
}
</script>
<style>
  .mc-report-card {
    position: relative;
    margin-bottom: 10px;
    border-radius: var(--border-radius-md);
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translate(0, -50%);
    color: rgba(255, 255, 255, .3);
    font-size: 3.6rem;
  }
</style>
